
::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
  	background: #f1f1f1; 
}

::-webkit-scrollbar-thumb {
  	background: #888; 
}

::-webkit-scrollbar-thumb:hover {
  	background: #555; 
}


@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

body{
	font-family: 'Rubik', sans-serif;
}

.header{
	width: 100%;
	background: #fff;
	padding: 5px 0px;
    position: relative;
    z-index: 9999;
}

.help_line{
	width: 100%;
	background: none;
}

.help_line h4 {
    text-align: left;
    font-size: 16px;
    margin: 0px;
    font-weight: 400;
}

.help_line h4 b {
    display: block;
    font-weight: 400;
    margin-top: 4px;
    color: #e84241;
}

.header_inside {
    width: 100%;
    background: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header_inside span{
	width: 33.33%;
}

.logo{
	width: 100%;
	background: none;
}

.logo img{
	display: block;
	width: 120px;
	margin: 0px auto;
}

.head_menus{
	width: 100%;
	background: none;
}

.head_menus ul{
	margin: 0px auto;
	padding: 0px;
	list-style: none;
	text-align: right
}

.head_menus li{
	display: inline-block;
	text-align: left;
    margin-left: 25px;
    cursor: pointer;
}

.head_menus li img{
	width: 22px;
}

.search_filter {
    width: 100%;
    background: #ffffffab;
    padding: 15px 0px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -70px;
    z-index: 999;
    -webkit-backdrop-filter: blur(80px);
    backdrop-filter: blur(80px);
    overflow: hidden;
}

.search_filter .form-control, .form-select {
    outline: none !important;
    font-size: 14px;
    padding: 8px 20px;
    border-radius: 3px;
	text-align: left;
	box-shadow: none !important;
	height: 40px;
    -webkit-appearance: none;
    border: 1px solid #CFCECE;
}

.btn-primary{
	outline: none;
    background: #E84242 !important;
    border: none !important;
	box-shadow: none !important;
	height: 40px;
}

.btn-primary img {
    width: 20px;
}

.cartImg_outer{
	width: 100%;
	background: none;
	position: relative;
}

.cartImg_outer b {
    background: #E84242;
    font-weight: 400;
    color: #fff;
    width: 20px;
    height: 20px;
    font-size: 12px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    top: -13px;
    right: -13px;
}

.banner_sec{
	width: 100%;
	background: #d6bbfe;
	padding: 20px 0px 0px;
}

.banner_inside{
	width: 100%;
	/*background: url('./images/banner1.svg');*/
	padding: 35px 0px;
    background-size: contain;
    background-repeat: no-repeat;
	background-position: center;
	min-height: 500px;
	position: relative;
}

.banner_content {
    width: 96.8%;
    background: none;
    position: relative;
    left: 0;
    right: 0;
    bottom: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 3%;
}

.banner_content h3 {
    text-align: left;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 2px;
    color: #fff;
}

.banner_content p {
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0;
    color: #fff;
}

.banner_content button {
    font-size: 11px;
    font-weight: 500;
    padding: 10px 15px;
    outline: none;
    border: none;
    text-align: center;
    border-radius: 3px;
    box-shadow: 0px 0px 1px #000;
    -webkit-transition: all .5s;
  -moz-transition: all .5s;
  transition: all .5s;
}

.banner_content button:hover {
    background: #e84241 !important;
    color: #fff !important;
}

.banner_content button img {
    width: 13px;
    margin-right: 7px;
}

.double_pics{
	width: 100%;
	background: none;
	padding: 30px 0px;
}

.pics_outer{
	width: 100%;
	background: none;
}

.pics_outer img{
	width: 100%;
	display: block;
	margin: 0px auto;
}

.howIt_works{
	width: 100%;
	background: none;
	padding: 0px;
}

.works_inside{
	width: 100%;
	background: none;
	padding: 25px 0px;
	box-shadow: 0px 4px 20px -7px #00000040;
	position: relative;
}

.works_inside ul {
    margin: 0px auto;
    padding: 0px;
    list-style: none;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.works_inside li {
    float: none;
    display: flex;
    width: 25%;
    justify-content: space-between;
    align-items: center;
	padding: 0 25px;
	border-right: 2px solid #e8e8e8;
}

.works_inside li:last-child{
	border: none;
}

.works_inside li span img{
	width: 45px;
    margin-right: 20px;
}

.works_inside li span h4{
	text-align: left;
    font-size: 14px;
    margin-bottom: 4px;
    color: #000;
}

.works_inside li span p{
	margin-bottom: 0;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
	line-height: 17px;
	overflow: hidden;
	display: -webkit-box; 
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.products_sec{
	width: 100%;
	padding: 60px 0px;
	background: #fff;
}

.nav-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-tabs .nav-link {
    border: none;
    border-bottom: 3px solid transparent;
    color: #000;
    font-weight: 100;
    padding: 8px 10px;
    margin: 0 10px;
    outline: none !important;
    text-transform: uppercase;
    font-size: 14px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
	border-bottom: 3px solid #E84242;
	color: #000;
	font-weight: 500;
}

.shops_sec{
	width: 100%;
	background: none;
	padding: 50px 0px;
}

.shopcard_outer {
    width: 100%;
    background: none;
    padding: 0px;
    position: relative;
	border: 1px solid #E8E8E8;
	transition: .3s ease-in;
	margin: 10px 0px;
	cursor: pointer;
}

.shopcard_outer:hover {
    box-shadow: 0px 5px 4px #bfbebe;
}

.shopcard_pics{
	width: 100%;
	background: none;
}

.shopcard_pics img {
    width: 100%;
    display: block;
    height: 220px;
    object-fit: cover;
}

.shopcard_data{
	width: 100%;
	background: none;
	padding: 15px 15px;
}

.shopcard_data h3 {
    text-align: left;
    margin-bottom: 5px;
    font-size: 19px;
    color: #333;
}

.shopcard_data p{
	margin-bottom: 10px;
    text-align: left;
    font-size: 14px;
    font-weight: 200;
	color: #3e3c3c;
	overflow: hidden;
	display: -webkit-box; 
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.shopcard_data h6 {
    margin-bottom: 5px;
    font-size: 12px;
    color: #333;
}

.shopcard_data h1 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 15px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #191818;
}

.shopcard_data h1 img {
    width: 18px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.footer{
	width: 100%;
	background: #F2F2F2;
	padding: 15px 0px;
}

.footer_inside {
    width: 100%;
    background: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer_inside span{
	width: 33.33%;
}

.footer_inside img {
    width: 125px;
}

.footer_inside h6 {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #000;
    margin: 0;
}

.footmenu{
	width: 100%;
	background: none;
	padding: 0px;
	text-align: right;
}

.footmenu ul {
    margin: 0px auto;
    padding: 0px;
    list-style: none;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.footmenu li {
    float: none;
    display: inline-block;
    margin-left: 25px;
}

.footmenu li img {
    width: 23px;
    cursor: pointer;
    outline: none;
    opacity: 1;
}

.footmenu li img:hover, .footmenu li img:focus{
    opacity: 1.0 !important;
}

.fixedHead {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9999;
}

.all_category{
    padding: 10px 0px;
    width: 100%;
    margin-top: 40px;
}

.all_category button {
    background: #D3D3D3 !important;
    font-size: 12px;
    width: 100%;
    outline: none !important;
    text-transform: uppercase;
    font-weight: 500;
    color: #000000;

}






.slideData{
    width: 100%;
    background: none;
    padding: 15px 0px;
}

.slideData span {
    float: none;
    display: inline-block;
    font-size: 12px;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.slideData span i {
    display: block;
    text-align: center;
    font-size: 20px;
    margin-bottom: 5px;
}

.modal-backdrop{
    z-index: 9999;

}

.modal{
    z-index: 99999;
    padding: 0px !important;
}

.modal-header h4{
    margin: 0;
    text-align: left;
    font-size: 14px;
    color: #525050;
}

.modal-header .btn-close{
    display: none;
}

.modal-dialog {
    max-width: 800px;
}

.prod_pic_outer{
    width: 100%;
    background: none;
    padding: 0px;
}

.prod_pic_outer img{
    width: 100%;
    display: block;
    margin: 0px auto;
}

.myprod_sec{
    width: 100%;
    background: none;
    padding: 15px 15px;
}
.newcategory_sec .modal-dialog .model-content .modal-body .myprod_sec{
    height: auto;
}

.prod_content{
    width: 100%;
    background: none;
    padding: 0px;
}

.prod_content h5{
    text-align: left;
    font-size: 17px;
    margin-bottom: 15px;
    color: #333;
}

.prod_content h5 span {
    font-size: 14px;
    margin-left: 15px;
    text-decoration: line-through;
    color: #808080;
    font-weight: 400;
}

.my_option{
    width: 100%;
    background: none;
    padding: 15px 0px;
}

.my_option h6 {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 15px;
}

.my_option ul{
    margin: 0px auto;
    padding: 0px;
    list-style: none;
    text-align: left;
}

.my_option li {
    display: block;
    font-size: 12px;
    color: #444;
    text-align: left;
    padding: 0px 0px;
    font-weight: 300;
}

.my_option li span{
    float: right;
}

.my_option label{
    display: block;
    font-size: 14px;
    color: #000;
    margin-bottom: 2px;
    text-align: left;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #E84242;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

[type="radio"]:checked + label, [type="radio"]:not(:checked) + label{
    width: 100%;
}

.form-group{
    margin-bottom: 25px;
}

.multi_pics{
    width: 100%;
    background: none;
    padding: 10px 0px;
}

.multi_pics ul {
    margin: 0px auto;
    padding: 0px;
    list-style: none;
    text-align: center;
}

.multi_pics li {
    float: none;
    display: inline-block;
    width: 25%;
}

.multi_pics li img {
    width: 100%;
    display: block;
    padding: 0 2px;
}

.my_description{
    width: 100%;
    background: none;
    padding: 20px 1px;
}

.my_description h6{
    text-align: left;
    margin-bottom: 15px;
    font-size: 15px;
}

.my_description p{
    margin-bottom: 0px;
}

.modalfoot_last{
    width: 100%;
    background: none;
    padding: 0px;
}

.modalfoot_last h3 {
    margin-bottom: 0px;
    font-size: 20px;
    font-weight: 400;
    color: #555;
}

.checked11 label{
    font-weight: 600 !important
}

.modalfoot_last button, .modal_cancel{
    margin-left: 10px;
}

.modal-content{
    border: none;
    border-radius: 0;
}

.qtyli_sec{
    width: 100%;
    background: none;
    padding: 10px 0px;
}

.qtyli_sec ul {
    margin: 0px auto;
    padding: 0px;
    list-style: none;
    text-align: left;
    display: flex;
    align-items: center;
    padding-left: 25px;
}

.qtyli_sec li {
    float: none;
    display: inline-block;
    padding: 0 6px;
    font-size: 17px;
}

.qtyli_sec li img {
    width: 20px;
    height: 20px;
}

.modalfoot_last button{
    font-size: 14px;
    margin-left: -13px;
    font-weight: 500;
    background: transparent;
    border: none;
    box-shadow: none;
    opacity: 1.0 !important;

}

.modalfoot_last button:hover{
    opacity: 0.7 !important;

}

.modalfoot_last button img {
    width: 25px;
    display: block;
    margin: auto;
    margin-bottom: 3px;
}

.modal_cancel {
    font-size: 11px;
    margin-left: -13px;
    background: transparent;
    border: none;
    box-shadow: none;
    outline: none !important;
    position: absolute;
    bottom: 5%;
    right: 110px;
}

.modal_cancel:hover{
    opacity: 1.0 !important;
}

.modal_cancel img {
    width: 25px;
    display: block;
    margin: auto;
    margin-bottom: 3px;
}

.myborder {
    width: 175px;
    margin-left: 2px;
    position: relative;
    right: -28px;
}

.shop_filter{
    width: 100%;
    background: none;
    padding: 15px 0px;
}

.shop_filter ul {
    margin: 0px auto;
    padding: 0px;
    list-style: none;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.shop_filter li{
    display: inline-block;
    float: none;
    margin-left: 25px;
}

.shop_filter li a{
    color: #999;
}

.shop_filter .active a{
    color: #FF0101;
}

.grid_card {
    width: 100%;
    background: none;
    display: flex;
    align-items: flex-start;
    border: 1px solid #e4e0e0;
    border-radius: 10px;
    overflow: hidden;
}

.grid_card img{
    width: 250px;
    display: block;
    margin: auto;
}

.prod_box_content{
    padding: 20px 25px;
    width: 100%;
    background: none;
}

.prod_box_content h2{
    text-align: left;
    margin-bottom: 15px;
    font-size: 22px;
    font-weight: 400;
}

.prod_box_content p {
    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
}

.prod_box_content h1{
    margin-bottom: 0;
    margin-top: 20px;
    font-size: 25px;
    font-weight: 700;
    text-align: left;
    color: #444;
}

.prod_box_content h2{
    color: #444;
}

img.bigarrow {
    width: 35px;
    margin-left: auto !important;
    display: block;
    margin: 0;
    position: relative;
    bottom: -20px;
}

.contact_sec{
    width: 100%;
    background: none;
    padding: 35px 0px;
}

.address_slot{
    width: 100%;
    background: none;
    padding: 0px;
}

.address_slot ul {
    margin: 0px auto;
    padding: 0px;
    list-style: none;
    display: block;
}

.address_slot li {
    float: none;
    display: flex;
    font-size: 14px;
    margin-bottom: 20px;
}

.address_slot li p{
    margin-bottom: 0px;
}

.address_slot li label{
    font-weight: bold;
    margin-right: 15px;
}

.span_label {
    display: inline-block;
    width: 90px;
}

.contact_sec h5{
    margin-bottom: 25px;
}

.contact_sec form{
    margin-top: 40px;
}

textarea.form-control {
    min-height: 150px;
    resize: none;
}

.form-control {
    padding: 0;
    border-radius: 0;
    outline: none;
    box-shadow: none !important;
    border: none;
    border-bottom: 1px solid #d8d8d8;
    font-size: 15px;
    padding-bottom: 5px;
}

.contact_sec button{
    height: auto;
    font-size: 13px;
    padding: 7px 25px;
    font-weight: 500;
    outline: none !important;
}

.hours_slote label ,.social_ico label {
    font-weight: bold;
    margin-right: 15px;
    font-size: 14px;
    margin-bottom: 0;
}

.hours_slote, .social_ico {
    margin-top: 55px;
}

.hours_slote ul{
    margin-top: 10px;
}

.hours_slote li {
    margin-bottom: 5px;
}

.social_ico ul{
    margin-top: 20px;
}

.social_ico li {
    display: inline-block;
    margin-right: 15px;
}

.social_ico li img {
    width: 20px;
}

.about_section{
    width: 100%;
    background: none;
    padding: 30px 0px;
}

.about_card{
    width: 100%;
    background: none;
    margin-bottom: 25px;
}

.about_card h6{
    text-align: left;
    font-size: 23px;
    font-weight: 400;
    margin-bottom: 10px;
}

.about_card  p{
    text-align: left;
    font-size: 15px;
    font-weight: 400;
    color: #555;
}

.my_option li{
    position: relative;
}

.my_option li input{
    left: 0;
    opacity: 0;
}

.my_option input[type=checkbox], .logined_sec input[type=radio] {
    display: inline-flex;
    width: 1.286em;
    height: 1.286em;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    border: 1px solid #ccc;
    border-radius: 0;
    margin-bottom: 0.3em;
    margin-right: 0.286em;
    font-size: 0.875em;
    cursor: pointer;
    -webkit-appearance: none;
    outline: none;
    width: 20px;
    height: 20px;
}

button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
.my_option input[type=checkbox]:checked:before {
    content: "";
    font-size: 0.643em;
    font-weight: 900;
    font-family: "Font Awesome 5 Free";
    display: block;
    line-height: normal;
    color: #fff;
    height: 4px;
    width: 10px;
    background: #fff;
    margin-top: -2px;
    border-bottom: 2px solid #e84241;
    border-left: 2px solid #e84241;
    transform: rotate(-37deg);
}

.myExtra li{
    display: flex;
}

.myExtra li label{
    width: 100%;
}

.checkout_sec{
    width: 100%;
    background: white;
    padding: 30px 0px;
    margin-top: 50px;
}

.my_header_bar{
    width: 100%;
    background: none;
    padding: 0px;
    margin-bottom: 30px;
}

.my_header_bar h4{
    margin: 0;
    text-align: center;
    font-weight: 300;
    color: #666;
    font-size: 25px;
}

.four_letter {
    width: 100%;
    background: #e2e2e2;
    padding: 10px 30px;
}

.four_letter h6 {
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 12px;
}

.checkout_sec label{
    display: block;
    font-size: 14px;
    margin-bottom: 14px;
    text-align: left;
}

.checkout_sec select.form-control{
    text-align: left;
    font-size: 14px;
    font-weight: 200;
    color: #777;
}

span.labelDown{
    margin-bottom: 0;
    margin-top: 15px;
    display: block;
    font-size: 13px;
    font-weight: 100;
    font-style: italic;
}

.checkout_data{
    width: 100%;
    background: none;
    padding: 15px 0px;
}

.checkout_data{
    width: 100%;
    background: none;
    padding: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 370px;
    padding-right: 15px;
}

.checkout_rows {
    width: 95%;
    background: none;
    padding: 10px 10px;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
}

.checkout_rows img{
    width: 90px;
    height: 70px;
}

.checkout_rows h3 {
    margin-bottom: 10px;
    text-align: left;
    font-size: 15px;
    font-weight: 600;
    color: #555;
    padding-left: 20px;
    line-height: 20px;
}

span.mainAmount {
    font-size: 17px !important;
}

.checkout_rows p{
    margin-bottom: 0;
    margin-left: 21px;
    font-weight: 100;
    color: #6F6D6D;
    font-size: 14px;
}

.steps_wizard{
    width: 100%;
    background: none;
    padding: 15px 0px;
}

.steps_wizard ul {
    margin-bottom: 35px;
    padding: 0px;
    list-style: none;
    display: flex;
    justify-content: space-evenly;
}

.steps_wizard li{
    float: none;
    display: block;
}

.steps_wizard li span {
    width: 27px;
    display: flex;
    font-weight: 500 !important;
    height: 27px;
    border-radius: 50%;
    border: 1px solid #E84242;
    text-align: center;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    color: #E84242;
    background: #fff;
    position: relative;
}

.muactive span {
    background: #E84242 !important;
    color: #fff !important;
}

.muactive{
    position: relative;
}

.steps_wizard ul{
    position: relative;
    margin-bottom: 5px;
    width: 180px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.steps_wizard ul:before {
    content: '';
    /* width: 100%; */
    width: 98% !important;
    height: 1px;
    display: block;
    background: #e84241;
    position: absolute;
    left: 2px !important;
    top: 13px;
    right: 0;
}

.step_form{
    width: 100%;
    background: none;
    margin-top: 25px;
    padding: 0 30px;
    border-left: 1px solid #b5b5b5;
    height: 100%;
}

.map_sec{
width: 100% !important; 
height: auto;
  width: 100%;
  position: relative;
  overflow: hidden;
}

/* .map_sec img{
    display: block;
    width: 100%;
    max-width: 100%;
    height: 150px;
} */

.next_btns{
    display: block;
    margin-left: auto;
    margin-top: 25px;
    background: #e84241;
    border: none;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    padding: 7px 20px;
    border-radius: 3px;
    outline: none !important;
}

.coupen_code{
    width: 100%;
    background: none;
    padding: 25px 0px;
}

.bottom_total {
    width: 100%;
    background: none;
    padding: 0px;
}

.bottom_total ul{
    margin: 0px auto;
    padding: 0px;
    text-align: right;
    list-style: none;
}

.bottom_total li {
    float: none;
    display: block;
    font-size: 15px;
    color: #666;
    line-height: 30px;
}

.bottom_total li b{
    color: #000;
}

.qrcodes{
    width: 100%;
    background: none;
    padding: 25px 0px;
}
.orderMethodsHeader{
    width: 100%;
    background: none;
    padding: 25px 0px;
}

.mysmile{
    width: 35px;
    display: block;
    margin: auto;
    margin-bottom: 15px;
}

.qrcodes p {
    margin-bottom: 0;
    font-size: 14px;
    text-align: center;
    line-height: 20px;
}

.orderMethodsHeader p {
    margin-bottom: 0;
    font-size: 14px;
    text-align: center;
    line-height: 20px;
}
.wwwMethodContent p {
    margin-bottom: 0;
    text-align: center;
    line-height: 20px;
    color: black;
    font-size: 18px;
    font-weight: 300;
    word-wrap: break-word 
}

.qrpics{
    display: block;
    margin: auto;
    margin-top: 20px;
    width: 140px;
}

.www_qrpics{
    display: block;
    margin: auto;
    margin-top: 20px;
    width: 250px;
}

.downloadQrCodeText{
    color: #655454;
    font-size: 10px;
    font-family: Roboto;
    font-weight: 400;
    word-wrap: break-word 
}

.wwwMethodContentNote{
    color: black;
    font-size: 13px;
    font-family: Roboto;
    font-weight: 400;
    word-wrap: break-word 
}

.whatsappBtn {
    background: #22A35D;
    border: none;
    color: #fff;
    margin-top: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 20px;
    font-weight: 600;
    font-size: 13px;
    border-radius: 5px;
    outline: none;
}
.placeOrderBtn {
    background: #E84242;
    border: none;
    color: #fff;
    margin-top: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 11px 90px;
    font-weight: 600;
    font-size: 10px;
    border-radius: 5px;
    outline: none;
}

.whatsappBtn img{
    width: 18px;
    position: relative;
    top: -1px;
    margin-right: 4px;
}

.download_modal{
    position: relative;
}

.download_qrs {
    position: relative;
    width: 194px;
    display: block;
    margin: auto;
}

.www_download_qrs {
    position: relative;
    width: 300px;
    display: block;
    margin: auto;
}

.downloaded_div{
    display: flex;
    align-items: center;
    line-height: 16px;
    font-weight: 500;
    font-size: 13px;
    justify-content: flex-end;
}

.downloaded_div img{
    margin-right: 10px;
}


.modal-dialog-custom {
    max-width: 100%;
}
.ModelCustom {
    padding-left: 0px !important;
}

.modal.show .modal-dialog{
    margin-top: 0px;
    margin-bottom: 0px;
}

.showOnTop{
    z-index: 999999999;
}

.checkoutModal .modal-content{
    background-color: #ffffff00;
}

.checkoutModal .form-control{
    background: transparent;
    border-bottom: 1px solid #ADAAAA;
}

.checkoutModal .form-control:focus, .checkoutModal .form-control:active{
    border-bottom: 1px solid #000 !important;
}

.checkoutModal .steps_wizard li span{
    background: #fff;
}

.footerFixed{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
}

.filter_form button.modal_cancel{
    bottom: 19%;
    right: 75px;
}

.swiper-button-next, .swiper-button-prev {
    width: 20px;
    height: 35px;
    background: white;
    position: absolute;
    top: 11px;
    z-index: 99999;
    cursor: pointer;
}

.swiper-button-next{
    right: 0;
    background-image: url('./images/groupImg.svg');
    background-repeat: no-repeat;
    background-size: 475px !important;
    background-position: -272px -120px;
    top: -5px;
    opacity: 1.0;
    background-color: #fff;
}

.swiper-button-next:hover{
    background-image: url('./images/groupImg.svg') !important;
    background-repeat: no-repeat !important;
    background-size: 475px !important;
    background-position: -296px -119px !important;
}

.swiper-button-next:before {
    content: '';
    /* background: #fff; */
    display: block;
    width: 43px;
    height: 31px;
    position: absolute;
    bottom: -30px;
    right: -7px;
}

.swiper-button-prev{
    left: 0;
    background-image: url('./images/groupImg.svg');
    background-repeat: no-repeat;
    background-size: 475px !important;
    background-position: -274px -92px;
    top: 0px;
    opacity: 1.0;
    background-color: #fff;
}

.swiper-button-prev:hover{
    background-image: url('./images/groupImg.svg') !important;
    background-repeat: no-repeat !important;
    background-size: 475px !important;
    background-position: -298px -90px !important;
}

.swiper-button-prev:after {
    content: '';
    background: #fff;
    display: block;
    width: 22px;
    height: 4px;
    position: absolute;
}

.swiper-button-prev:before {
    content: '';
    background: #fff;
    display: block;
    width: 22px;
    height: 31px;
    position: absolute;
    bottom: -30px;
    left: -7px;
}

.swiper{
    padding: 0 10px;
}

.swiper-wrapper{
    padding: 0 0px;
}

.swiper-button-prev:before{
    display: none;
}

.swiper-slide-active1 {
    border-bottom: 2px solid #E84242;
    font-weight: bold;
}
.swiper-slide-active2 {
    border-bottom: 2px solid #d3bebe;
    font-weight: bold;
}


.swiper-slide-active2 {
    border-bottom: 2px solid hsl(0, 13%, 92%);
}
.shop_filter li a img {
    width: 20px;
}

.modal-header{
    background: #E8E7E7;
}

ul.slideImages{
    margin: 0px;
    padding: 0px;
    list-style: none;
    text-align: left;
}

ul.slideImages li {
    float: none;
    display: inline-block;
    width: 100px;
    text-align: center;
    cursor: pointer;
    position: relative;
}

ul.slideImages li img {
    width: 10px;
    opacity: 0.7;
}

.modal_slide_leftR{
    width: 15px !important;
    position: absolute;
    left: 42px;
    top: -13px;
}

.modal_slide_rightR {
    width: 10px !important;
    position: absolute;
    right: 45px;
    top: -13px;
}

ul.slideImages li img:hover {
    opacity: 1.0;
}

.header_enter {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mySwiper2 .swiper-button-prev, .mySwiper2 .swiper-button-next{
    visibility: hidden;
}

.mySwiper2 .swiper-slide-active{
    border: none !important
}

.prod_qty{
    margin: 0px;
    padding: 0px;
    list-style: none;
    text-align: left
}

.prod_qty li {
    float: none;
    display: inline-block;
    font-size: 17px;
    padding: 0 5px;
    color: #444;
}

.checkoutModal .form-control:focus, .checkoutModal .form-control:active {
    border-bottom: 1px solid #e84241 !important;
}

.prod_qty li img{
    cursor: pointer;
    object-fit: contain;
}

.checkout_rows{
    position: relative;
}

.crossImg{
    background-image: url('./images/cancelNew1.svg');
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: -23px 3px;
    position: absolute;
    right: -33px;
    top: 35%;
    background-size: 45px;
    border-radius: 50%;
    cursor: pointer;
}
.crossImg:hover{
    background-position: 3px 2px !important;
}

.track_btn{
    font-size: 14px;
    background: #e84241;
    border: none;
    color: #fff;
    padding: 7px 15px;
    outline: none;
    border-radius: 3px;
}

.track_sec .modal-dialog {
    max-width: 100%;
    height: calc(100vh - 0px);
    background: #fff;
    padding: 115px 0px 50px;
}

.my_prod_inside {
    width: 100%;
    background: #fbf8f8;
    padding: 0px;
    max-width: 450px;
    margin: auto;
    border: 1px solid #ececec;
}

.myprod_header {
    width: 100%;
    padding: 15px 20px;
}
.newcategory_changes .myprod_header {
    position: fixed;
    width: 79.5%;
    z-index: 9999;
}
.line-for-catgegory{
    background: #e3dfdf;
    padding: 11px;
    position: absolute;
    top: 75%;
    padding: 0 0 14px 80%;
    z-index: 9999;
    left: 10%;
}
.newcategory_changes {
    transition: none !important;
}
.newcategory_changes .modal-dialog{
    transition: none !important;
}
.myprod_header img{
    width:20px !important;
    height:20px;
    background-color: transparent;
    background-image: url("./images/myCrossR.svg");
    background-repeat: no-repeat;
    padding:10px 10px;
}

.myprod_header img:hover{
    background-image: url("./images//myCrossG.svg");
}
.myprod_header h5{
    font-size: 15px;
    margin-bottom: 0;
    font-weight: 400;
    color: #444;
}
.modal-backdrop{
    background-color:  transparent !important;
}
.myprod_header h5 i{
    float: right;
    color: #999;
}

.myprod_header h4{
    font-size: 20px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 0;
    color: #777;
}

.myprod_body{
    width: 100%;
    padding: 20px 20px;
}

.myprod_body label {
    display: block;
    margin-bottom: 8px;
    font-size: 15px;
    color: #666;
}

.myprod_body input {
    border: 1px solid #efefef;
    padding: 8px 15px;
    outline: none;
    text-align: left;
    color: #444;
}

.checkBtns{
    background: #e84241;
    border: none;
    font-size: 13px;
    padding: 6px 20px;
    display: block;
    margin-left: auto;
    border-radius: 3px;
    outline: none;
    font-weight: 500;
    color: #fff;
}

.trackList{
    width: 100%;
    background: none;
    padding: 0px;
}

.trackList h3 {
    text-align: center;
    font-weight: 400;
    font-size: 23px;
    color: #444;
    margin: 0;
    margin-bottom: 50px;
}

.trackList_card{
    width: 100%;
    background: #F4F5F7;
    padding: 25px 20px;
}

.trackList_card ul{
    margin: 0px;
    padding: 0px;
    list-style: none;
    text-align: left
}

.trackList_card li {
    display: block;
    line-height: 30px;
    text-align: left;
    font-size: 15px;
    color: #444;
}

.trackList_card li i{
    color: #E84242;
    cursor: pointer;
}

.trackList_card li span {
    color: #69a721;
}

.table>:not(:first-child) {
    border-top: none;
}

tbody, td, tfoot, th, thead, tr{
    font-size: 13px;
    text-align: center;
}

tbody td img {
    width: 65px;
}

.prod_total{
    margin: 0px;
    padding: 0px;
    list-style: none;
    text-align: right;
    margin-bottom: 25px;
}

.prod_total li{
    font-size: 14px;
    text-align: left;
}

.prod_total li span{
    float: right;
}

.resp_footer{
    display: none;
}

.openSidebar{
    display: none;
}

.sidebar_sec {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    background: #ffffff4f;
    padding-top: 66px;
    z-index: 999;
    padding: 100px 0px 40px;
    -webkit-backdrop-filter: blur(80px);
    backdrop-filter: blur(80px);
    min-width: 210px;
    border-left: 1px solid #bbb;
    display: none;
}

.sidebar_menus{
    width: 100%;
    background: none;
    padding: 20px 0px;
}

.sidebar_menus ul{
    margin: 0px auto;
    padding: 0px;
    list-style: none;
    text-align: left;
}

.sidebar_menus li {
    float: none;
    display: block;
    text-align: left;
    font-size: 13px;
    font-weight: 500;
    padding: 9px 0px;
    display: flex;
    align-items: center;
}

.sidebar_menus li img {
    width: 16px;
    margin-right: 7px;
}

.side_lang{
    width: 100%;
    background: none;
    padding: 20px 0px;
}

.side_lang p {
    margin-bottom: 0px;
    font-size: 12px;
    text-align: left;
}

.side_lang p img {
    width: 22px;
    margin-right: 3px;
}

.side_social{
    width: 100%;
    background: none;
    padding: 10px 0px;
}

.side_social ul{
    margin: 0px auto;
    padding: 0px;
    list-style: none;
    text-align: left;
}

.side_social li {
    float: none;
    display: inline-block;
    margin-right: 20px;
}

.side_social li img {
    width: 18px;
}

.sidedown{
    width: 100%;
    background: none;
    padding: 15px 0px;
    margin-top: 35%;
}

.sidedown h6 {
    text-align: left;
    font-size: 12px;
}

.sidedown h6 span{
    display: block;
    color: #f00;
}

.sidedown p{
    margin-bottom: 0;
    font-size: 10px;
    margin-top: 30px;
    text-align: left;
}

.myactive {
    color: #f00;
    font-weight: 500;
}

.showAlldiv{
    display: block !important;
}

.allCategories{
    width: 100%;
    background: none;
}

.allCategories ul{
    margin: 0px;
    padding: 0px;
    list-style: none;
    text-align: left
}

.allCategories li {
    text-align: center;
    font-size: 13px;
    padding: 10px 10px;
    cursor: pointer;
}

.allCategories li:hover {
    background: #D6D3D3;
    color: #000;
    font-weight: 500;
}

.newcategory_sec .myprod_body{
    padding: 20px 0px;
}

.lastmenus{
    width: 100%;
    background: none;
    padding: 10px 0px;
    display: none;
}

.lastmenus ul {
    margin: 0px auto;
    padding: 0px;
    list-style: none;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.lastmenus li {
    float: none;
    display: inline-block;
    font-size: 10px;
}

.lastmenus li img {
    display: block;
    margin: 0px auto;
    width: 15px;
    margin-bottom: 2px;
}

.filter_div{
    width: 100%;
    padding: 0px 20px;
    background: none;
    position: relative;
}

.filter_div .myprod_body label{
    font-size: 14px;
}

.filter_div .form-control {
    background: transparent !important;
    font-size: 13px;
    outline: none;
}

.work_slider {
    float: none;
    display: flex;
    width: 25%;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    border-right: 2px solid #e8e8e8;
}

.works_slidedata{
    display: none;    
    width: 100%;
    background: none;
    padding: 25px 0px;
    box-shadow: 0px 4px 20px -7px #00000040;
    position: relative;
}

.deskDouble_slide{
    display: none;
}

.scrollCart{
    width: 100%;
    background: none;
    padding: 0px;
}

.remove_del .checkout_rows::before{
    display: none;
}

.max_height120{
    max-height: calc(100vh - 200px);
    overflow: auto;
}

.myExtra li input{
    opacity: 1.0 !important;
}

.backBtns {
    font-size: 13px;
    background: #e84241;
    border: none;
    color: #fff;
    font-weight: 500;
    padding: 5px 20px;
    border-radius: 3px;
    outline: none !important;
}

a {
    color: #0d6efd;
    text-decoration: none !important;
}

.modal_cancel{
    display: block !important;
}

.gotTop {
    display: block;
    position: fixed;
    bottom: 15px;
    right: 25px;
    width: 35px;
    height: 35px;
    /* background: #b30a13; */
    color: #000 !important;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    z-index: 9999999;
}

.gotTop img {
    display: block;
    margin-bottom: 2px !important;
    width: 27px;
    margin: auto;
}

.form-control:focus{
    border-color: #e84241 !important;
}

.inputOuter{
    position: relative
}

.inputOuter .form-control {
    padding-right: 35px;
    width: 100%;
}

.inputOuter button {
    position: absolute;
    top: 0;
    right: 0;
}

.btn-primary:hover{
    background: #a91d1d !important;
}

.prod_qty li:hover {
    color: #000 !important;
}

.mySwiper2 .swiper-button-prev, .mySwiper2 .swiper-button-next{
    visibility: visible;  
    margin-top: 45%;
}

ul.custom_radio{
    margin: 0px;
    margin-top: 10px;
}

ul.custom_radio li {
    display: block;
    text-align: left;
    margin-bottom: 4px;
}

ul.custom_radio li label {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

ul.custom_radio li label b {
    display: flex;
    align-items: center;
    padding-left: 2px;
    font-weight: 400;
    color: #555;
}

/* ul.custom_radio li input{
    margin-right: 10px;
    opacity: 1.0;
    position: relative !important;
} */

.myradio {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
  
.myradio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
  
.checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: #eee;
    border-radius: 50%;
    border: 1px solid #e84241;
    overflow: hidden;
}
  
.myradio:hover input ~ .checkmark {
    background-color: #fff;
}
  
.myradio input:checked ~ .checkmark {
    background-color: #fff;
}
  
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
  
.myradio input:checked ~ .checkmark:after {
    display: block;
    background: #e84241;
}
  
.myradio .checkmark:after {
    top: 2px;
    left: 2px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: white;
}

.changeBG label, .changeBG b {
    font-weight: 500 !important;
    color: #e84241 !important;
}

.newcategory_sec .myprod_body {
    padding: 20px 0px;
    position: relative;
}

.blurshade {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(253, 253, 253, 0);
    border: 1px solid #CCCCCC;
    backdrop-filter: blur(80px);
}

.allCategories{
    position: relative;
}


.searchBtn{
    width: 25px;
    height: 25px;
    background: url('./images/search.svg')
}

.trackBtns{
    width: 20px !important;
    height: 20px;
    background: url('./images/groupImg.svg');
    display: block;
    background-size: 430px;
    background-position: -191px -168px;
    background-repeat: no-repeat;
    cursor: pointer;
}

.trackBtns:hover{
    background-position: -213px -168px !important;
}

.engLanguage{
    width: 20px !important;
    height: 20px;
    background: url('./images/groupImg.svg');
    display: block;
    background-size: 430px;
    background-position: -78px -36px;
    background-repeat: no-repeat;
    cursor: pointer;
}

.engLanguage:hover{
    background-position: -47px -38px !important;
}

.boldWeight{
    font-weight: 600;
    color: #E84242;
}

.modal-footer{
    padding: 0px;
}

.double_pics .work_slider{
    width: 100%;
    padding: 0;
}

.double_pics .swiper-slide-active{
    border: none !important
}

.double_pics .swiper-button-prev, .double_pics .swiper-button-next{
    display: block;
    top: 40%;
}


.double_pics .swiper-button-prev:after, .double_pics .swiper-button-prev:before{
    display: none;
}
.double_pics .swiper-button-next:before{
    display: none;
}

.fontBold{
    font-weight: 600;
}

.fontBold label{
    font-weight: 600 !important;
    color: #E84242 !important;
}

.mytransparent{
    background: transparent !important;
}

option:checked, option:hover {
    color: white;
    background: #e84241 repeat url("data:image/gif;base64,R0lGO...") !important;
}

option:hover {
    background-color: #e84241 !important; 
} 

.form-select:focus {
    border-color: #e84241;
}

.checkout_rows h3{
    width: 95%;
}

.coupen_code{
    padding: 25px 0px 0;
}

.bottom_total li{
    line-height: 25px;
}

.steps_wizard ul{
    margin-bottom: 5px;
}

.form-group {
    margin-bottom: 10px;
}

.checkout_rows{
    margin-top: 5px;
}

.checkout_sec label{
    margin-bottom: 5px;
}
.newcategory_changes{
    height: 75% !important;
}
.modal.show .modal-dialog {
    height: auto;
    transform: none;
    min-height: calc(100vh - 0px);
    background: #ffffffba !important;
}
.newcategory_changes .modal-dialog {
    overflow: scroll !important;
    height: 100% !important;
}
.newcategory_changes .modal-dialog .modal-content{
    overflow: scroll !important;
    height: 100% !important;
}
.grid_card img{
    height: 200px;
}

.prod_box_content{
    height: 200px;
}

img.bigarrow{
    height: auto;
    bottom: 25px;
}

.ajDev{
    background: transparent !important;
}

#clearBtn{
    background: none;
    box-shadow: none;
    border: none;
    outline: none !important;
    margin-top: 6px;
}

#clearBtn img {
    width: 18px;
}

.boldWeight .shopIcoGray, .boldWeight .aboutIcoGray, .boldWeight .contactIcoGray{
    display: none !important;
}

.boldWeight .shopIcoRed, .boldWeight .aboutIcoRed, .boldWeight .contactIcoRed{
    display: block !important;
}

.modal-backdrop.show {
    opacity: 0;
}

.track_sec{
    background: #fff;
}

.addmyCaRD{
    background: #000000ba !important;
}

.addmyCaRD .modal-dialog{
    min-height: auto !important;
    background: #ffffffba !important;
}

.gotTop:hover span{
    color: #E84242 !important;
}

.addRed {
    color: #ff160f;
    font-weight: 500;
}

.backBtns{
    background: #716b6b;
}
.gotTop{
    display: none !important;
}

.swiper-wrapper{
    padding: 0px 30px;
}

.checkout_sec{
    min-height: calc(100vh - 0px);
    align-items: center;
    display: flex;
}

.checkoutModal .modal-body{
    padding: 0px;
}

.hideModule{
    display: none;
}

.swiper-backface-hidden .swiper-slide{
    margin: 0 8px !important;
}


/* //////////////////////////////// 
Responsive Web Application ////////
//////////////////////////////// */


@media screen and (min-width: 300px) and (max-width: 767px){

.hero_image{
    margin-top: 65px;
    max-height: 340px;
}
.all-btn-none{
    display: none;
}
span.helpline{display: none;}
.logo img{width: 95px;}
.head_menus ul{display: flex; justify-content: flex-end; align-items: center;}
.head_menus li{margin-left: 10px;}
.head_menus li img {width: 18px;}
.cartImg_outer b{width: 15px; height: 15px; font-size: 8px; top: -8px; right: -10px;}

.header{padding: 8px 0px; position: fixed; top: 0; left: 0; right: 0;}
.banner_sec{padding: 20px 0px 0px; margin-top: 65px;}
.banner_inside{padding: 0px 0px 35px; background-position: top; min-height: 195px; position: relative;}

.banner_content h3{font-size: 18px; margin-bottom: 5px;}
.banner_content button{font-size: 0px; padding: 10px 10px;}
.banner_content button img {width: 14px; margin-right: 0px;}
.banner_content p{font-size: 9px;}

.works_inside ul{display: block;}
.works_inside li{width: 100%; border-right: none; padding: 10px 25px;}
.works_inside{padding: 10px 0px;}
.double_pics{padding: 0px 0px;}

.nav-tabs{display: none;}
.products_sec{padding: 20px 0px 60px;}
.all_category{margin-top: 0px;}

.slideData span{font-size: 9px;}
.swiper-button-prev, .swiper-button-next{display: none;}
.shops_sec{padding-top: 0px; padding-bottom: 0px;}
.shop_filter{display: none;}
.shopcard_data h3{font-size: 14px;}

.shopcard_data p{font-size: 11px; margin-bottom: 5px;}
.shopcard_data h6{font-size: 9px;}
.shopcard_data h1 {font-size: 12px;}
.shopcard_data h1 img {width: 14px;}

.all_category button{display: none !important}
.slideData{padding-top: 0px;}

.footer{display: none;}
.resp_footer {display: block; width: 100%; background: #EFEFEF; padding: 10px 0px; position: relative; z-index: 999;}
.respfoot_inside{width: 100%; background: none; }
.respfoot_inside ul{margin: 0px auto; padding: 0px; list-style: none;  display: flex}
.respfoot_inside li { text-align: center; font-size: 11px; width: 25%;}
.respfoot_inside li img { display: block; margin: auto; width: 14px; height: 14px; object-fit: contain; margin-bottom: 3px;}

.hideSearch{display: none !important;}
.openSidebar{margin-left: 15px; display: block;}

.filter_form .col-xs-5{width: 40%; max-width: 40%;}
.filter_form .col-xs-4{width: 30%; max-width: 30%;}
.col-xs-3{width: 15%; max-width: 15%;}
.filter_form button.modal_cancel {bottom: 19%; right: 5px; font-size: 11px !important; margin-left: 25px;}
.modal_cancel img {width: 18px;}

.paddR0{padding-right: 0px;}
.paddL0{padding-left: 0px;}
.paddR5{padding-right: 5px;}
.paddL5{padding-left: 5px;}

.search_filter .form-control, .form-select{border: 1px solid #d8d8d8; padding: 6px 10px; height: auto; font-size: 11px; outline: none !important; background-image: none;}
.submitBtns{padding: 0px 5px; width: 100%; height: 29px; margin-top: 1px; border-radius: 5px;}
.submitBtns img {width: 14px;}

.search_filter{padding: 10px 0px; bottom: -35px; border-bottom: 1px solid #eaeaea; border-top: 1px solid #eaeaea;}
.checkout_sec{padding :15px 0px 60px;}
.modal-dialog{margin: 0px auto;}
.modal-body{padding: 15px 5px;}

.checkout_sec label {display: block; font-size: 13px; margin-bottom: 10px;}
.checkout_sec select.form-control{font-size: 13px;}
span.labelDown{margin-top: 10px; font-size: 10px;}

.checkout_data{overflow-y: auto; max-height: 250px; padding-right: 4px;}
.checkout_rows img {width: 50px; height: 50px;}
.checkout_rows {width: 92%; padding: 5px 0px; padding-right: 0px; border-radius: 3px; margin-top: 10px;}
.checkout_rows h3 {margin-bottom: 5px; font-size: 10px; padding-left: 5px; line-height: 12px;}
.checkout_rows p {    margin-bottom: 0; margin-left: 6px; font-size: 10px; line-height: 10px; letter-spacing: -1px;}
.prod_qty li{font-size: 13px; padding: 0 2px;}
.prod_qty li img{width: 10px !important; height: 10px !important; object-fit: contain !important;}

.mainAmount{width: 96px !important; font-size: 13px !important; margin-top: 3px;}
.checkout_rows::before {width: 20px; height: 20px; right: -25px; top: 35%; background-size: 20px;}
.coupen_code{display: block; padding: 17px 0px 5px;}
.bottom_total{margin-top: 15px;}
.bottom_total li{font-size: 12px; line-height: 20px;}

.steps_wizard ul:before{width: 100px; left: 35%; top: 15px;}
.steps_wizard li span {width: 30px; height: 30px; font-size: 13px;}


.newcategory_sec .modal-dialog { margin-top: 35% !important; margin-bottom: 0px !important; max-width: 80%;}
.newcategory_sec .modal-body{padding :0px;}
.newcategory_sec .myprod_sec{padding: 0px;}
.newcategory_sec .myprod_header h5 {font-size: 13px; font-weight: 500 !important;}
.myprod_header h5 { font-size: 13px; font-weight: 500 !important;}
.myprod_header h5 img { float: right; margin-top: 0px; width: 13px;}

.addtocard_modal .modal-header h4{font-size: 14px;}
.addtocard_modal ul.slideImages{ display: flex; align-items: center; justify-content: space-evenly;}
.addtocard_modal ul.slideImages li{width: 35px;}
.addtocard_modal ul.slideImages li img {width: 10px;}
.addtocard_modal .swiper-wrapper {padding: 0;}
.addtocard_modal .myprod_sec{padding: 0px;}

.addtocard_modal .modal-body { padding: 5px 5px;}
.multi_pics{padding: 3px 0px}
.prod_content{padding: 20px 15px 0px;}
.prod_content .form-group {margin-bottom: 15px;}
.my_option label{font-size: 14px;}
.my_option ul{margin-top: 10px; padding-left: 0px;}
.my_option li label{font-size: 12px;}
.checkmark{top: 0;}

.my_description{padding: 0px 15px 20px;}
.my_description p{font-size: 13px; overflow: hidden; display: -webkit-box;  -webkit-line-clamp: 4; -webkit-box-orient: vertical;}

.width25{width: 25%; max-width: 25%;}
.width60 {
    width: 55%;
    max-width: 55%;
}
.width15 {
    width: 20%;
    max-width: 20%;
}
.mobile-cart-section{
    width: 55%;
    max-width: 55%;
    }
.modalfoot_last{padding: 5px 10px; margin: 0;}
.qtyli_sec ul{padding-left: 0px;}
.qtyli_sec li{padding: 0 4px; font-size: 14px;}
.qtyli_sec li img { width: 10px; height: 20px;}
.modalfoot_last h3{font-size: 16px}
.modalfoot_last button {font-size: 9px; margin-left: 12px; width: auto; right: 0; height: 30px;}
.bigmodal_lose{font-size: 12px !important; margin-left: 0 !important; margin-top: 5px;}

.modalfoot_last button img{width: 19px; margin-bottom: 2px;}
.four_letter{padding: 5px 15px;}

.products_sec .nav-tabs{display: flex; position: fixed; bottom: 0; width: 100%; right: 0; left: 0; bottom: 8px; background: #fff; margin-bottom: 0 !important; z-index: 9999998; opacity: 0;}
.address_slot li{font-size: 13px;margin-bottom: 10px;} 
.span_label {width: 85px;}
.hours_slote, .social_ico {margin-top: 30px;}
.social_ico li img {width: 17px;}
.contact_sec h5 {margin-bottom: 15px; font-size: 15px; margin-top: 25px;}
.contact_sec form {margin-top: 20px;}
.contact_sec .form-control{font-size: 13px;}

.about_card h6 {font-size: 17px;font-weight: 500;}
.about_card p {text-align: justify; font-size: 12px;}
.about_card{margin-top: 20px !important}
.about_section{padding: 10px 0px;}
.lastmenus, .resp_footer {display: block; position: fixed; bottom: 0; left: 0; right: 0; z-index: 9999999; background: #eaeaea; border-top: 1px solid #d4d4d4;}

.steps_wizard ul{margin-bottom: 0px;}
.step_form{padding: 0px; border: none;}
.step_form label{margin-bottom: 0px;}
/* .map_sec{display: none;} */

.checkoutModal .modal-content .modal_cancel{font-size: 14px; margin-left: 13px; position: absolute; bottom: 0; right: auto; left: 0; margin-bottom: 70px;}
.qrcodes{padding: 25px 0px 50px;}
.orderMethodsHeader{padding: 25px 0px 50px;}
.whatsappBtn{margin-top: 30px; padding: 8px 30px; font-size: 12px;}
.addtocard_modal{z-index: 9999999; background: #000000c7;}
.placeOrderBtn{margin-top: 30px; padding: 8px 30px; font-size: 12px;}


.prod_box_content{display: block}
.grid_card img {width: 110px; height: 146px; object-fit: cover;}
.prod_box_content {padding: 10px 15px; position: relative;}
.prod_box_content h2 {text-align: left; margin-bottom: 5px; font-size: 15px; font-weight: 500;}
.prod_box_content h1 {font-size: 18px; margin-top: 11px;}

img.bigarrow {width: 19px !important; position: absolute; bottom: 5px; right: 15px; height: auto;}
.grid_card{margin-top: 15px;}
.prod_box_content p {font-size: 11px; margin-bottom: 5px;line-height: 15px;}

.works_slidedata .swiper-wrapper {padding: 0 5px;}
.work_slider img{width: 25px; margin-right: 10px;}

.lastmenus li{width: 33.33%; text-align: center}
.works_inside{display: none;}
.works_slidedata{display: block !important; padding: 10px 0px;}
.work_slider{width: 100%; padding: 0px 0px; border: none !important;}
.work_slider h4{font-size: 11px;}
.work_slider p{margin-bottom: 0; font-weight: 400 !important; text-align: left; font-size: 8px; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;}

.works_slidedata .swiper-slide-active{border: none !important}
.deskDouble_data{display: none;}
.deskDouble_slide{display: block !important;}
.deskDouble_slide .work_slider img {width: 100%;}
.deskDouble_slide .swiper-wrapper {padding: 0;}
.deskDouble_slide .swiper-slide-active {border-bottom: none ;}

#grid_view .width50{width: 50%;}
.padR5{padding-right: 5px;}
.padL5{padding-left: 5px;}
.shopcard_pics img{height: auto;}
.shopcard_data{padding: 10px 10px;}

.shopcard_outer{margin: 5px 0px;}
.lastmenus li img{margin-bottom: 5px;}
.hours_slote label, .social_ico label{font-size: 12px;}

.fade:not(.show) {opacity: 1;}
.width60{width: 60%;}
.width25{width: 25%;}
.mrgR0{margin-right: 0px;}
.mrgL0{margin-left: 0px;}
.coupen_code input.form-control{font-size: 11px; padding-top: 5px;}
.coupen_code .next_btns{font-size: 10px; margin-top: 15px;}
.works_slidedata .work_slider{width: 100%; padding: 0 10px; border-right: 1px solid #f1f0f0 !important;}

/* .modal-content{min-height: calc(100vh - 0px);} */
.myprod_sec .row{--bs-gutter-x: 0rem !important;}
.track_sec .modal-dialog{padding-top: 70px;}
.myprod_header{padding: 10px 15px;}
.myprod_header h4 {font-size: 15px;}
.myprod_body { width: 100%; padding: 15px 15px;}
.myprod_body label{font-size: 14px; margin-bottom: 5px;}
.myprod_body input{font-size: 12px;}

.trackList h3 {text-align: center; font-weight: 600; font-size: 17px; margin-bottom: 25px;}
.trackList_card{padding: 20px 15px; margin-bottom: 5px;}
.trackList_card li{font-size: 14px;}
.myprod_sec{padding-bottom: 70px;}
tbody, td, tfoot, th, thead, tr{font-size: 10px;}
.table_outer{overflow: auto;}
.prod_total li {font-size: 12px; text-align: left; margin-bottom: 10px;}
.prod_total{margin-top: 20px;}
.checkBtns{font-size: 11px;}

.backBtns{font-size: 11px; display: block; margin-bottom: 0px; background: #e84241; border: none; color: #fff; font-weight: 500; padding: 5px 10px; border-radius: 3px; min-width: 60px; outline: none !important;}
.checkoutModal .modal-content .modal_cancel{display: block !important}
/* .minHeight100 .modal-content{min-height: calc(100vh - 0px) !important;} */

.steps_wizard{border-top: 1px solid #b7b7b7; margin-top: 20px; padding-top: 25px;}
span.mainAmount {font-size: 12px !important;}
.prod_content h5 span{font-size: 13px; color: #c3c3c3;}
.modal_slide_rightR{right: 12px; top: -8px;}
.modal_slide_leftR{left: 11px; top: -8px;}

.inputOuter button{font-size: 9px; width: 28px; padding: 0; border-radius: 0; height: 28px;}
.modal {z-index: 9999999; padding: 0px !important; background: #000000a6;}
.newcategory_sec {background: transparent;}
.newcategory_sec .my_prod_inside{background: transparent; border: 1px solid #c5c5c5;}
.newcategory_sec .modal-content{background: transparent; position: relative}
.crossImg{right: -29px;}
.my_description p{-webkit-line-clamp: inherit; text-align: justify}

/* .scrollCart{min-height: calc(100vh - 100px)}; */
.crossImg{width: 20px; height: 20px; background-position: -21px 2px; background-size: 40px;}
.max_height120{max-height: calc(100vh - 133px);}
#clearBtn {display: block; margin-top: 0;}
#clearBtn img {width: 13px;}
#clearBtnX{display: block !important; border-radius: 5px;}
.banner_content{display: flex !important}
.gotTop{display:none !important; bottom: 38px; right: 10px; width: 25px; height: 25px; font-size: 12px; z-index: 99999999;}
.modal.show .modal-dialog{min-height: auto; background: transparent !important;}

.checkoutModal{background: transparent !important;}
.checkout_sec select.form-control {color: #000;}
.modal-body{padding-bottom: 0px;}
.track_sec .modal-dialog{display: flex; align-items: center; max-width: 350px;}
.inputOuter input{padding-right: 25px !important;}
span.qtynumber { display: inline-block; padding: 3px 10px; font-size: 12px; font-weight: 500;}

.all_category .col-md-10{padding-left: 15px !important;}
.all_category .swiper-slide {width: auto !important; margin-right: 15px !important;}
.all_category .slideData span i{font-size: 15px;}
.track_sec .modal-content{max-height: calc(100vh - 180px); overflow: auto;}
.swiper-wrapper{padding: 0px;}

}

@media screen and (min-width: 1500px) and (max-width: 2500px){

    .checkoutModal .modal-content{
        height: calc(100vh - 0px);
    }

}

@media screen and (min-width: 992px) and (max-width: 2500px){

    .resp_footer{
        display: none !important;
    }

}


@media screen and (max-width: 406px){
    .hero_image{
        width: 407px;
        height: 185px;
    }
}

@media screen and (max-width: 768px){
      .modalfoot_last button {font-size: 9px; margin-left: 28%; width: 100%; right: 0; height: 30px;}
      .mobile-cart-section a { width: 55%; }

      .mobile-cart-section{
        width: 55%;
        max-width: 55%;
        }
    }
@media only screen and (max-width: 480px){
    .Toastify__toast-container {
      width: 88vw;
      padding: 0;
      left: 22px;
      margin: 0;
    }
  }

.selectedCategory{
    font-size: 20px;
    color: red
}

.orderMethodsRadioButton{
    position: static !important ;
}
.orderMethod{
  border: 1px solid #CCCCCC;
  padding: 10px 10px 8px 27px;
  width: 43%;
  margin-left: 28.5%;
  margin-top: 2%;
  border-radius: 3px;
}


.orderMethod img{
    width: 38px;
}

.orderMethod:hover {
    border: 1px solid #e84242;
}
.wwwMethodContent{
    display: none;
}

.orderMethodsRadioButton {
    -webkit-appearance: none;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    outline: none;
    border: 2px solid #e84242;
    display: inline-flex;
    margin-right: 5px;
}

.orderMethodsRadioButton:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
}

.orderMethodsRadioButton:checked:before {
    background: #e84242;
    
}

.orderMethodsRadioButton:checked {
  border-color:#e84242;
}
@media screen and (max-width: 768px){
    .placeOrderBtn{
        width: 100%;
    }
    .orderMethod{
        width: 100%;
        margin-left: 0;
    }
}

.text-truncate-2lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word; /* Prevents words from breaking awkwardly */
    white-space: normal; /* Ensures wrapping works properly */
    max-height: 3em; /* Approximate height for 2 lines */
    line-height: 1.5em; /* Adjust based on font */
}
.product_badge{
    position: absolute;
    width: 100px;
    text-align: center;
    font-size: 10px;
    right: 8px;
    top: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: bold;
    height: 22px;
  }
  